import TYPES from '@/types';

// Application
import {
  GetCreateCustomerKuspitAccountStatusQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-account/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsCreatingAccountKuspitViewModel {
  @Inject(TYPES.GET_CREATE_CUSTOMER_KUSPIT_ACCOUNT_STATUS_QUERY)
  private readonly getCreateCustomerKuspitAccountStatusQuery!:
    GetCreateCustomerKuspitAccountStatusQuery;

  readonly i18n_namespace = 'components.contract-savings.creating_account.kuspit';

  i18n_savings_namespace = 'components.contract-savings';

  private timeout?: NodeJS.Timer;

  readonly timeout_milliseconds = parseInt(process.env.VUE_APP_ONBOARDING_TIMEOUT || '180000', 10);

  show_timeout_advice = false;

  process = 'creación de tu cuenta SOWOS Pocket';

  get status() {
    return this.getCreateCustomerKuspitAccountStatusQuery.execute();
  }

  get accountWasCreated() {
    const last_step = this.status;

    return last_step.percent === 100 && last_step.step_number === 3;
  }

  showTimeout = () => {
    if (this.status.percent < 100) {
      this.show_timeout_advice = true;
    }
  }

  initialize = () => {
    this.timeout = setTimeout(() => {
      this.showTimeout();
    }, this.timeout_milliseconds);
  }
}
